import React from "react"
// Components
import { graphql,useStaticQuery } from 'gatsby';
import Img from 'gatsby-image';

const PhotographyImage = () => {
  const data = useStaticQuery(graphql`
    query {
      placeholderImage: file(relativePath: { eq: "photography/me-taking-photo.jpg" }) {
        childImageSharp {
          fluid(
            maxWidth: 600
            traceSVG: { color: "#BDBDBD" }
          ) {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }
    }
  `)

  if (!data?.placeholderImage?.childImageSharp?.fluid) {
    return <div>Picture not found</div>
  }

  return (
    <>
      <Img
        alt={"Dave Judd taking a photozzz"}
        fluid={data.placeholderImage.childImageSharp.fluid}
      />
    </>
  )
}

export default PhotographyImage;