import styled from 'styled-components';
import { BorderedImage,ShadowedBox } from '../ui.styles';

const Section = styled.section`
  margin-bottom: 70px;
`;

export const Intro = styled(Section)`

`;

export const Photos = styled(Section)`

`;

export const BlurbBox = styled.div`
  ${ShadowedBox};
  width: 100%;
  padding: 50px;
  display: flex;
  flex-direction: column-reverse;
  align-items: center;

  @media (min-width: 768px) {
    flex-direction: row;
  }

  .gatsby-image-wrapper {
    width: 100%;
    max-width: 260px;
    height: auto;
    margin: 0 0 20px 0;

    @media (min-width: 768px) {
      max-width: 100%;
      flex-basis: 30%;
      margin: 0 0 0 60px;
    }
  }

  img {
    ${BorderedImage}
  }
`;

export const BlurbText = styled.p`
  flex: 1;
  font-size: 2rem;
  line-height: 130%;
  letter-spacing: .5px;

  &:not(:last-of-type) {
    margin-bottom: 20px;
  }
`;

export const PhotoBoxes = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-top: 20px;
`;
