import React from 'react';
// Components
import Img from 'gatsby-image';
// Style
import {
  Container,
  Inner
} from './PhotoBox.styles';

const PhotoBox = ({ alt,image,orientation }) => {

  return(
    <Container orientation={orientation}>
      <Inner>
        <Img fluid={image} alt={alt} />
      </Inner>
    </Container>
  );
}

export default PhotoBox;