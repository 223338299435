import React from "react"
// Components
import { graphql,StaticQuery } from 'gatsby';
import Layout from "../components/layout"
import { Seo } from "../components/Seo"
import { Container1200 } from '../components/Container1200';
import { PageTitle } from '../components/PageTitle';
import { PhotoBox } from '../components/PhotoBox';
import { PhotographyImage } from '../components/PhotographyImage';
import { TitleWithLine } from '../components/TitleWithLine';
// Style
import {
  BlurbText,
  BlurbBox,
  Intro,
  PhotoBoxes,
  Photos
} from '../styles/pages-styles/photography.styles';

const Photography = () => {
  const query = graphql`
    query PhotographyItemsQuery {
      allPhotographyJson {
        edges {
          node {
            alt
            id
            image {
              childImageSharp {
                fluid(
                  maxWidth: 384
                  quality: 100
                  traceSVG: { color: "#BDBDBD" }
                ) {
                  ...GatsbyImageSharpFluid_tracedSVG
                }
              }
            }
            orientation
          }
        }
      }
    }
  `;

  return (
    <Layout>
      <Seo title="Web Development | DaveJudd.com" />
      <Intro id="intro">
        <Container1200>
          <PageTitle>Photography</PageTitle>
          <BlurbBox>
            <BlurbText>I really enjoy photography as a growing side hobby. Check out some of my favorites below, or see the my collection here:<br /><br /><a href="https://fineartamerica.com/profiles/dave-judd?tab=artworkgalleries&artworkgalleryid=971697" target="_blank" rel="noreferrer">Fine Art America <i className="fas fa-external-link-alt"></i></a></BlurbText>
            <PhotographyImage />
          </BlurbBox>
        </Container1200>
      </Intro>
      <Photos id="photos">
        <Container1200>
          <TitleWithLine>Favorite Photos</TitleWithLine>
          <PhotoBoxes>
            <StaticQuery
              query={query}
              render={data => (
                  <>
                    {data.allPhotographyJson.edges.map(({ node }) => (
                        <PhotoBox
                          key={node.id}
                          alt={node.alt}
                          image={node.image.childImageSharp.fluid}
                          orientation={node.orientation}
                        />
                      )
                    )}
                  </>
              )}
            >
            </StaticQuery>
          </PhotoBoxes>
        </Container1200>
      </Photos>
    </Layout>
  )
}

export default Photography;
