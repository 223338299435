import styled from 'styled-components';
import { colors as c } from '../../styles/colors';

export const Container = styled.div`
  flex-basis: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 15px;

  @media (min-width: 450px) {
    flex-basis: 50%;
  }

  @media (min-width: 768px) {
    flex-basis: 33.333%;
  }

  @media (min-width: 992px) {
    flex-basis: 25%;
  }

  ${({ orientation }) => orientation !== "v" && `
    padding: 0 20px 10px;
  `}

  ${({ orientation }) => orientation === "v" && `
    padding: 0 50px 10px;
  `}
`;

export const Inner = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 2px 3px 0px rgba(0, 0, 0, 0.1);

  .gatsby-image-wrapper {
    width: 100%;
  }

  img {
    padding: 8px;
    background-color: #fff;
    height: auto;
    border: 1px solid ${c.grayBorder};
  }
`;
